'use client'

import { useEffect, useState } from 'react'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useLocation } from '@marketplace-web/shared/browser'

import useBanners from 'hooks/useBanners'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { MultiVariantsOnboardingModalModel, SingleStepOnboardingModalModel } from 'types/models'
import { clickEvent, viewEvent, viewScreenEvent } from '_libs/common/event-tracker/events'
import { removeParamsFromQuery, urlWithParams } from '_libs/utils/url'

type Props = {
  banner: MultiVariantsOnboardingModalModel
  singleStepBanner?: SingleStepOnboardingModalModel
}

const useOnboarding = ({ banner, singleStepBanner }: Props) => {
  const { onBannerSeen, setSeenBannerTypeToCookies } = useBanners()
  const { track } = useTracking()
  const [isOpen, setIsOpen] = useState(true)
  const [slide, setSlide] = useState(0)
  const { replaceHistoryState, relativeUrl, urlQuery, searchParams } = useLocation()

  const isSingleStepModalEnabled = singleStepBanner?.abTest.group.toLowerCase() === 'on'
  const slideName = banner.steps[slide]?.name

  useEffect(() => {
    if (isSingleStepModalEnabled) {
      onBannerSeen({ type: 'single_step_onboarding_modal', name: banner.name })
      setSeenBannerTypeToCookies('multi_variants_onboarding_modal')

      return
    }

    onBannerSeen({ type: 'multi_variants_onboarding_modal', name: banner.name })
  }, [isSingleStepModalEnabled, setSeenBannerTypeToCookies, banner.name, onBannerSeen])

  useEffect(() => {
    track(viewScreenEvent({ screen: banner.name }))
  }, [banner.name, track])

  useEffect(() => {
    if (!isOpen) return
    if (searchParams.onboarding_modal_status) return

    const updatedUrl = urlWithParams(relativeUrl, {
      ...searchParams,
      onboarding_modal_status: 'shown',
    })

    replaceHistoryState(updatedUrl)
  }, [isOpen, replaceHistoryState, relativeUrl, searchParams])

  useEffect(() => {
    if (isSingleStepModalEnabled) return
    if (!slideName) return

    track(
      viewEvent({
        screen: banner.name,
        target: ViewableElement.OnboardingModalCard,
        targetDetails: slideName,
      }),
    )
  }, [banner.name, slideName, track, isSingleStepModalEnabled])

  const handleClose = () => {
    const urlWithoutParam = removeParamsFromQuery(relativeUrl, urlQuery, [
      'onboarding_modal_status',
    ])

    replaceHistoryState(urlWithoutParam)
    setIsOpen(false)
  }

  const handleModalClose = () => {
    if (isSingleStepModalEnabled) {
      track(
        clickEvent({
          screen: banner.name,
          target: ClickableElement.CloseScreen,
        }),
      )
    } else {
      track(
        clickEvent({
          screen: banner.name,
          target: ClickableElement.CloseScreen,
          targetDetails: slideName,
        }),
      )
    }

    handleClose()
  }

  const handlePrimaryClick = () => {
    if (isSingleStepModalEnabled) {
      track(
        clickEvent({
          screen: banner.name,
          target: ClickableElement.UploadItem,
        }),
      )

      return
    }

    track(
      clickEvent({
        screen: banner.name,
        target: ClickableElement.UploadItem,
        targetDetails: slideName,
      }),
    )
  }

  return {
    isOpen,
    isSingleStepModalEnabled,
    onModalClose: handleModalClose,
    onPrimaryClick: handlePrimaryClick,
    setSlide,
  }
}

export default useOnboarding
