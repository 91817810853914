'use client'

import { useSelector } from 'react-redux'

import { useFetchClosetPromotions } from '@marketplace-web/domain/vas'
import { ResponseError } from '@marketplace-web/shared/api-client'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { clickEvent } from '_libs/common/event-tracker/events'
import PersonalizationBanner from 'components/PersonalizationBanner'
import PortalDraftItemReminderBanner from 'components/PortalDraftItemReminderBanner'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { getBanners } from 'state/banners/selectors'

import BlockTitle from '../common/BlockTitle'
import DynamicSpacer from '../common/DynamicSpacer'
import ItemsLoader from './components/ItemsLoader'
import ItemsGrid from './components/ItemsGrid'
import useFetchItems from './hooks/useFetchFeedItems'
import useTabs from '../hooks/useTabs'

type Props = {
  onEmpty: (reason: ResponseError) => void
}

const Feed = ({ onEmpty }: Props) => {
  const banners = useSelector(getBanners)

  const { currentTab } = useTabs()
  const { track } = useTracking()

  const { events, endReached, isLoading, showLoadMoreButton, fetchFeedEvents, isFirstPageFetched } =
    useFetchItems({
      onEmpty,
    })
  const { closets, fetchClosetPromo } = useFetchClosetPromotions({
    isEnabled: currentTab.feed.arePromotedClosetsEnabled,
  })

  async function fetchMoreItems(withClosets: boolean) {
    const [setItems, setClosets] = await Promise.all([
      fetchFeedEvents(),
      withClosets ? fetchClosetPromo() : null,
    ])

    setClosets?.()
    setItems?.()
  }

  function handleLoadMoreButtonClick() {
    track(clickEvent({ target: ClickableElement.FeedLoadMoreButton }))

    fetchMoreItems(true)
  }

  function renderPersonalizationBanner() {
    if (!endReached || !banners.feedPersonalizationBanner) return null

    return <PersonalizationBanner banner={banners.feedPersonalizationBanner} />
  }

  function renderPortalDraftReminderBanner() {
    if (!banners.portalMergeDraftItemsReminder) return null

    return <PortalDraftItemReminderBanner banner={banners.portalMergeDraftItemsReminder} />
  }

  return (
    <>
      {isFirstPageFetched && (
        <>
          <BlockTitle title={currentTab.feed.title} />
          <DynamicSpacer phones="Regular" tabletsUp="Large" />
        </>
      )}
      <ItemsGrid events={events} closets={closets} />
      <ItemsLoader
        endReached={endReached}
        isLoading={isLoading}
        showLoadMoreButton={showLoadMoreButton}
        handleLoadMoreButtonClick={handleLoadMoreButtonClick}
        onPageEnd={() => fetchMoreItems(!isFirstPageFetched)}
      />
      {renderPortalDraftReminderBanner()}
      {renderPersonalizationBanner()}
    </>
  )
}

export default Feed
