'use client'

import { useCallback, useContext, useMemo } from 'react'

import {
  useFetchVasEntryPoints,
  useHomeClosetOrAd,
  VasEntryPoint,
} from '@marketplace-web/domain/vas'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { ErrorBoundary, GridItem as GridItemComponent } from '@marketplace-web/shared/ui-helpers'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { transformFeedItemDtoToProductItem } from 'data/transformers/product-item'
import { buildGridItems, feedEventToItem } from 'data/utils/grid'
import { GridItem } from 'types/components'
import { FeedEventDto } from 'types/dtos'
import { ClosetModel, GenericPromoBoxModel, ListerActivationBannerModel } from 'types/models'
import { PromoBoxType } from '_libs/common/braze/constants'
import { clickEvent, favouriteItemEvent } from '_libs/common/event-tracker/events'
import useBrazePromoBoxes from '_libs/common/braze/hooks/useBrazePromoBoxes'
import PromoBox from '_libs/common/braze/components/PromoBox'
import ListerActivationBanner from 'components/ListerActivationBanner'
import { ProductItem } from 'components/ProductItem'
import { BrazeContext } from '_libs/common/braze/containers/BrazeProvider'
import ControlPromoBoxRenderItemWrapper from '_libs/common/braze/components/ControlPromoBoxRenderItemWrapper'
import { addClickListItemTracking } from 'containers/TrackingProvider/hocs'

import { useHomeContext } from '../../HomeProvider'
import useTabs from '../../hooks/useTabs'
import useItemVisibilityTracking from '../hooks/useItemVisibilityTracking'
import useItemInserts, { ItemInsertType } from '../hooks/useItemInserts'

type Props = {
  events: Array<FeedEventDto>
  closets: Array<ClosetModel>
}

const TrackedProductItem = addClickListItemTracking(ProductItem)
const TrackedPromoBox = addClickListItemTracking(PromoBox)

const LISTER_ACTIVATION_BREAKPOINT_ITEM_COUNT = {
  phones: 1,
  tablets: 3,
  default: 4,
}

const ItemsGrid = ({ events, closets }: Props) => {
  const { track } = useTracking()
  const { logCardImpression, logCardClick } = useContext(BrazeContext)
  const promoBoxes = useBrazePromoBoxes(PromoBoxType.Braze)
  const { homepageSessionId } = useHomeContext()
  const { currentTab } = useTabs()
  const { data: vasEntryPoints } = useFetchVasEntryPoints(
    [VasEntryPoint.PromotedClosets],
    !currentTab.feed.arePromotedClosetsEnabled,
  )

  const { handleItemVisibility } = useItemVisibilityTracking(homepageSessionId)
  const { getItemInserts, handleBannerDismiss } = useItemInserts({ closets, promoBoxes })
  const { renderClosetOrAdComponent } = useHomeClosetOrAd({
    ...currentTab.feed,
    vasEntryPoints,
    closets,
    homepageSessionId,
  })

  const handleFavouriteToggle = ({
    itemId,
    isFollowEvent,
    itemContentSource,
  }: {
    itemId: number
    isFollowEvent: boolean
    itemContentSource?: ContentSource | null
  }) => {
    track(clickEvent({ target: ClickableElement.Favourite }))
    track(
      favouriteItemEvent({
        itemId,
        isFollowEvent,
        homepageSessionId,
        contentSource: itemContentSource,
      }),
    )
  }

  const getBaseGridItems = useCallback(() => {
    const eventItems: Array<GridItem<FeedEventDto | Record<string, never>>> =
      events.map(feedEventToItem)

    return eventItems
  }, [events])

  const handleBrazePromoBoxClick = (promoBox: GenericPromoBoxModel) => () => {
    if (!promoBox.url) return

    logCardClick(promoBox.id)
  }

  const handleBrazePromoBoxVisibility = (promoBox: GenericPromoBoxModel) => () => {
    logCardImpression(promoBox.id)
  }

  const renderItem = (
    item: GridItem<FeedEventDto | Record<string, never> | ItemInsertType>,
    index: number,
  ) => {
    const { data } = item

    switch (item.type) {
      case 'item': {
        const feedEvent = data as FeedEventDto<'item'>

        return (
          <TrackedProductItem
            tracking={{
              id: feedEvent.entity.id,
              contentType: ListItemContentType.Item,
              contentSource: feedEvent.entity.content_source,
              position: index + 1,
              homepageSessionId,
            }}
            testId="feed-item"
            item={transformFeedItemDtoToProductItem(feedEvent.entity)}
            showStatus
            showStatusAsBadge
            onFavouriteToggle={handleFavouriteToggle}
          />
        )
      }

      case 'box': {
        const feedEvent = data as FeedEventDto<'box'>

        return (
          <TrackedPromoBox
            image={feedEvent.entity.photo.url}
            color={feedEvent.entity.photo.dominant_color}
            url={feedEvent.entity.web_link}
            impressionUrl={feedEvent.entity.impression_url}
            tracking={{
              id: feedEvent.entity.id,
              contentSource: ContentSource.Promo,
              contentType: ListItemContentType.Promo,
              position: index + 1,
            }}
            testId="feed-legacy"
          />
        )
      }

      case 'generic_promo_box': {
        const promoBox = data as GenericPromoBoxModel

        return (
          <PromoBox
            image={promoBox.imageUrl}
            color={promoBox.backgroundColor}
            url={promoBox.url}
            impressionUrl={promoBox.impressionUrl}
            onClick={handleBrazePromoBoxClick(promoBox)}
            onEnter={handleBrazePromoBoxVisibility(promoBox)}
            testId="feed-braze"
          />
        )
      }

      case 'closet_or_ad': {
        return renderClosetOrAdComponent(index + 1, item.id)
      }

      case 'lister_activation_banner': {
        const banner = data as ListerActivationBannerModel

        return (
          <ListerActivationBanner
            breakpointItemCount={LISTER_ACTIVATION_BREAKPOINT_ITEM_COUNT}
            banner={banner}
            onClose={handleBannerDismiss}
          />
        )
      }

      default:
        return null
    }
  }

  function resolveItemWidth(item: GridItem<object>) {
    switch (item.type) {
      case 'lister_activation_banner':
      case 'closet_or_ad':
        return 'full-row'
      case 'item':
      case 'box':
      case 'generic_promo_box':
        return 'one-fifth'
      default:
        return null
    }
  }

  const gridItems = useMemo(() => {
    const itemInserts = getItemInserts()

    return buildGridItems(getBaseGridItems(), itemInserts)
  }, [getBaseGridItems, getItemInserts])

  return (
    <div className="feed-grid">
      {gridItems.map((item, index) => (
        <GridItemComponent
          key={item.id}
          width={resolveItemWidth(item)}
          onVisible={() => handleItemVisibility({ item, index })}
        >
          <ErrorBoundary FallbackComponent={ErrorBoundary.ComponentError}>
            <ControlPromoBoxRenderItemWrapper
              promoBoxes={currentTab.feed.isPromoBoxEnabled ? promoBoxes : []}
              index={index}
            >
              {renderItem(item, index)}
            </ControlPromoBoxRenderItemWrapper>
          </ErrorBoundary>
        </GridItemComponent>
      ))}
    </div>
  )
}

export default ItemsGrid
